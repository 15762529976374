.Tradition-overlay{
    background-color: rgba(255, 255, 255, 0.85);
    width: 100%;
    min-height: 100vh;
    padding: 2rem 1rem 3rem 1rem;
}

.Tradition-quote{
    font-weight: bold;
    font-size: 2rem;
    line-height: 3.5rem;
    margin-bottom: 2rem;
    color: #C71C4F;
}

.Tradition-icon{
    height: 30px;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.Tradition-source{
    font-weight: 600;
    color: #C71C4F;
    font-size: 1rem;
    margin: 2rem 0rem 3rem 0rem;
}

.Tradition-button{
    border-radius: 100rem;
    margin: 1rem 1rem;
    border: 3px solid #1e1cb9;
    background-color: #1e1cb9;
    font-size: 1.2rem;
    padding: 1rem 2rem;
    font-weight: 600;
    color: white;
}

.Tradition-repeat-button{
    border-radius: 100rem;
    margin: 1rem 1rem;
    border: 3px solid #C71C4F;
    background-color: transparent;
    font-size: 1.2rem;
    padding: 1rem 2rem;
    font-weight: 600;
    color: #C71C4F;

    -webkit-animation: offHoverFadet 0.5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: offHoverFadet 0.5s; /* Firefox < 16 */
        -ms-animation: offHoverFadet 0.5s; /* Internet Explorer */
         -o-animation: offHoverFadet 0.5s; /* Opera < 12.1 */
            animation: offHoverFadet 0.5s;
}

.Tradition-repeat-button:hover{
    border-radius: 100rem;
    margin: 1rem 1rem;
    border: 3px solid #C71C4F;
    background-color: #C71C4F;
    font-size: 1.2rem;
    padding: 1rem 2rem;
    font-weight: 600;
    color: white;

    -webkit-animation: onHoverFade 0.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: onHoverFade 0.5s; /* Firefox < 16 */
     -ms-animation: onHoverFade 0.5s; /* Internet Explorer */
      -o-animation: onHoverFade 0.5s; /* Opera < 12.1 */
         animation: onHoverFade 0.5s;
}

@media only screen and (max-width: 768px) {
    .Tradition-overlay{
        min-height: 100vh;
        padding: 2rem 0.8rem 3rem 0.8rem;
    }
    .Tradition-quote{
        font-size: 1.6rem;
        line-height: 2.2rem;
    }
    .Tradition-icon{
        height: 18px;
    }
    .Tradition-source{
        font-size: 0.9rem;
        line-height: 1.5rem;
        margin: 2rem 0rem 2rem 0rem;
    }
    .Tradition-button{
        font-size: 1rem;
        padding: 0.8rem 1.6rem;
    }
    
    .Tradition-repeat-button{
        font-size: 1rem;
        padding: 0.8rem 1.6rem;
        -webkit-animation: ignore 0.5s; /* Safari, Chrome and Opera > 12.1 */
            -moz-animation: ignore 0.5s; /* Firefox < 16 */
                -ms-animation: ignore 0.5s; /* Internet Explorer */
                -o-animation: ignore 0.5s; /* Opera < 12.1 */
                    animation: ignore 0.5s;
    }
    .Tradition-repeat-button:hover{
        font-size: 1rem;
        padding: 0.8rem 1.6rem;
        -webkit-animation: ignore 0.5s; /* Safari, Chrome and Opera > 12.1 */
            -moz-animation: ignore 0.5s; /* Firefox < 16 */
                -ms-animation: ignore 0.5s; /* Internet Explorer */
                -o-animation: ignore 0.5s; /* Opera < 12.1 */
                    animation: ignore 0.5s;
    }
}


@keyframes onHoverFade {
    from {     
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: transparent;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: #C71C4F; 
    }
    to   {
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: #C71C4F;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: white;
    }
}

/* Firefox < 16 */
@-moz-keyframes onHoverFade {
    from {     
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: transparent;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: #C71C4F; 
    }
    to   {
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: #C71C4F;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: white;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes onHoverFade {
    from {     
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: transparent;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: #C71C4F; 
    }
    to   {
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: #C71C4F;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: white;
    }
}

/* Internet Explorer */
@-ms-keyframes onHoverFade {
    from {     
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: transparent;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: #C71C4F; 
    }
    to   {
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: #C71C4F;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: white;
    }
}

/* Opera < 12.1 */
@-o-keyframes onHoverFade {
    from {     
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: transparent;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: #C71C4F; 
    }
    to   {
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: #C71C4F;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: white;
    }
}

/************ FADE OUT ************/
@keyframes offHoverFadet {
    to {     
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: transparent;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: #C71C4F; 
    }
    from   {
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: #C71C4F;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: white;
    }
}

/* Firefox < 16 */
@-moz-keyframes offHoverFadet {
    to {     
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: transparent;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: #C71C4F; 
    }
    from   {
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: #C71C4F;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: white;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes offHoverFadet {
    to {     
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: transparent;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: #C71C4F; 
    }
    from   {
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: #C71C4F;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: white;
    }
}

/* Internet Explorer */
@-ms-keyframes offHoverFadet {
    to {     
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: transparent;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: #C71C4F; 
    }
    from   {
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: #C71C4F;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: white;
    }
}

/* Opera < 12.1 */
@-o-keyframes offHoverFadet {
    to {     
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: transparent;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: #C71C4F; 
    }
    from   {
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: #C71C4F;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: white;
    }
}