/*App CSS*/
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Poppins;
  min-height: 100vh;
}
@media only screen and (max-height: 600px) {
  .App {
    min-height: 110vh;
  }
}

.App-logo {
  height: 75px;
  margin-bottom: 1rem;
}

.App-title{
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
.App-text{
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 2.5rem;
}

.App-secondary-button{
  border-radius: 100rem;
  margin: 0.5rem 0rem;
  border: 2px solid white;
  background-color: transparent;
  font-size: 1.25rem;
  padding: 1rem 2rem;
  font-weight: bold;
  color: white;
  font-family: Poppins;
  margin-right: 15px;

  -webkit-animation: fadeout 0.5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadeout 0.5s; /* Firefox < 16 */
        -ms-animation: fadeout 0.5s; /* Internet Explorer */
         -o-animation: fadeout 0.5s; /* Opera < 12.1 */
            animation: fadeout 0.5s;
}

.App-secondary-button:hover{

  border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid  white;
    background-color: white;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: #C71C4F;
    font-family: Poppins;
    cursor:pointer;
    margin-right: 15px; 

    -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.5s; /* Firefox < 16 */
        -ms-animation: fadein 0.5s; /* Internet Explorer */
         -o-animation: fadein 0.5s; /* Opera < 12.1 */
            animation: fadein 0.5s;

}

.App-know-more-button{
  border-radius: 100rem;
  margin: 0.5rem 0rem;
  border:0px;
  background-color: white;
  font-size: 1rem;
  padding: 1rem 2rem;
  font-weight: 500;
  color: #C71C4F;
  font-family: Poppins;
  margin-right: 15px;
}
.App-
.App-link {
  color: #61dafb;
}

.App-link-details{
  text-decoration: underline;
  color:white;
  font-weight: 600;
  text-decoration: none;
}

.App-link-details:hover{
  text-decoration: underline;
  color:white;
  font-weight: 600;
}

.App-details{
  background-color: white;
  min-width: 75%;
  padding: 10px;
}

/******** MOBILE ********/

@media only screen and (max-height: 560px) {
  .App-text{
      margin-bottom: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .App-title{
    font-size: 2.1rem;
  }
  .App-text{
    font-size: 1.15rem !important;
    font-weight: 500;
    margin-bottom: 2.5rem;
  }
}


/******** ANIMATIONS ********/
.fade-enter{
  opacity:0.01;
}
.fade-enter.fade-enter-active{
  opacity:1;
  transition: opacity 450ms ease-in;
}
.fade-exit{
  opacity:1;
}
.fade-exit.fade-exit-active{
  opacity:0.01;
  transition: opacity 450ms ease-in;
}


@keyframes fadein {
  from { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid white;
    background-color: transparent;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: white;
    font-family: Poppins;
    margin-right: 15px; }
  to   { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid  white;
    background-color: white;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: #C71C4F;
    font-family: Poppins;
    cursor:pointer;
    margin-right: 15px; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid white;
    background-color: transparent;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: white;
    font-family: Poppins;
    margin-right: 15px; }
  to   { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid  white;
    background-color: white;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: #C71C4F;
    font-family: Poppins;
    cursor:pointer;
    margin-right: 15px; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid white;
    background-color: transparent;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: white;
    font-family: Poppins;
    margin-right: 15px; }
  to   { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid  white;
    background-color: white;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: #C71C4F;
    font-family: Poppins;
    cursor:pointer;
    margin-right: 15px; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid white;
    background-color: transparent;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: white;
    font-family: Poppins;
    margin-right: 15px; }
  to   { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid  white;
    background-color: white;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: #C71C4F;
    font-family: Poppins;
    cursor:pointer;
    margin-right: 15px; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid white;
    background-color: transparent;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: white;
    font-family: Poppins;
    margin-right: 15px; }
  to   { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid  white;
    background-color: white;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: #C71C4F;
    font-family: Poppins;
    cursor:pointer;
    margin-right: 15px; }
}

/************ FADE OUT ************/
@keyframes fadeout {
  to { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid white;
    background-color: transparent;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: white;
    font-family: Poppins;
    margin-right: 15px; }
  from   { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid  white;
    background-color: white;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: #C71C4F;
    font-family: Poppins;
    cursor:pointer;
    margin-right: 15px; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  to { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid white;
    background-color: transparent;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: white;
    font-family: Poppins;
    margin-right: 15px; }
  from   { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid  white;
    background-color: white;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: #C71C4F;
    font-family: Poppins;
    cursor:pointer;
    margin-right: 15px; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  to { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid white;
    background-color: transparent;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: white;
    font-family: Poppins;
    margin-right: 15px; }
  from   { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid  white;
    background-color: white;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: #C71C4F;
    font-family: Poppins;
    cursor:pointer;
    margin-right: 15px; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  to { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid white;
    background-color: transparent;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: white;
    font-family: Poppins;
    margin-right: 15px; }
  from   { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid  white;
    background-color: white;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: #C71C4F;
    font-family: Poppins;
    cursor:pointer;
    margin-right: 15px; }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
  to { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid white;
    background-color: transparent;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: white;
    font-family: Poppins;
    margin-right: 15px; }
  from   { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid  white;
    background-color: white;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: #C71C4F;
    font-family: Poppins;
    cursor:pointer;
    margin-right: 15px; }
}