@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap);
/*Import Poppins from Google Fonts*/

body {
  margin: 0;
  font-family: 'Poppins','Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*App CSS*/
.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: Poppins;
  min-height: 100vh;
}
@media only screen and (max-height: 600px) {
  .App {
    min-height: 110vh;
  }
}

.App-logo {
  height: 75px;
  margin-bottom: 1rem;
}

.App-title{
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}
.App-text{
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 2.5rem;
}

.App-secondary-button{
  border-radius: 100rem;
  margin: 0.5rem 0rem;
  border: 2px solid white;
  background-color: transparent;
  font-size: 1.25rem;
  padding: 1rem 2rem;
  font-weight: bold;
  color: white;
  font-family: Poppins;
  margin-right: 15px; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
  animation: fadeout 0.5s;
}

.App-secondary-button:hover{

  border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid  white;
    background-color: white;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: #C71C4F;
    font-family: Poppins;
    cursor:pointer;
    margin-right: 15px; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
    animation: fadein 0.5s;

}

.App-know-more-button{
  border-radius: 100rem;
  margin: 0.5rem 0rem;
  border:0px;
  background-color: white;
  font-size: 1rem;
  padding: 1rem 2rem;
  font-weight: 500;
  color: #C71C4F;
  font-family: Poppins;
  margin-right: 15px;
}
.App-
.App-link {
  color: #61dafb;
}

.App-link-details{
  text-decoration: underline;
  color:white;
  font-weight: 600;
  text-decoration: none;
}

.App-link-details:hover{
  text-decoration: underline;
  color:white;
  font-weight: 600;
}

.App-details{
  background-color: white;
  min-width: 75%;
  padding: 10px;
}

/******** MOBILE ********/

@media only screen and (max-height: 560px) {
  .App-text{
      margin-bottom: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .App-title{
    font-size: 2.1rem;
  }
  .App-text{
    font-size: 1.15rem !important;
    font-weight: 500;
    margin-bottom: 2.5rem;
  }
}


/******** ANIMATIONS ********/
.fade-enter{
  opacity:0.01;
}
.fade-enter.fade-enter-active{
  opacity:1;
  transition: opacity 450ms ease-in;
}
.fade-exit{
  opacity:1;
}
.fade-exit.fade-exit-active{
  opacity:0.01;
  transition: opacity 450ms ease-in;
}


@keyframes fadein {
  from { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid white;
    background-color: transparent;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: white;
    font-family: Poppins;
    margin-right: 15px; }
  to   { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid  white;
    background-color: white;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: #C71C4F;
    font-family: Poppins;
    cursor:pointer;
    margin-right: 15px; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */

/* Internet Explorer */

/* Opera < 12.1 */

/************ FADE OUT ************/
@keyframes fadeout {
  to { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid white;
    background-color: transparent;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: white;
    font-family: Poppins;
    margin-right: 15px; }
  from   { border-radius: 100rem;
    margin: 0.5rem 0rem;
    border: 2px solid  white;
    background-color: white;
    font-size: 1.25rem;
    padding: 1rem 2rem;
    font-weight: bold;
    color: #C71C4F;
    font-family: Poppins;
    cursor:pointer;
    margin-right: 15px; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */

/* Internet Explorer */

/* Opera < 12.1 */
.Home-container {
    padding:1.1rem;
}

.Home-alert{
    background-color: rgba(255, 255, 255, 0.15) !important;
    border-color:rgba(255, 255, 255, 0.35) !important;
    margin-top: 2rem;
    cursor: pointer;
    font-weight: 500;
    padding: 1.25rem 1.5rem;
    display: inline-block; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
    animation: modalFadeOut 0.5s;
}

.Home-modal{
    min-width: 70vw !important;
}

/******** MOBILE ********/

@media only screen and (max-width: 768px) {
    .Home-alert{
      font-size: 0.85rem;
    }
}

.Home-alert:hover{
    background-color: #C71C4F !important;
    border-color:#C71C4F !important;
    margin-top: 2rem;
    cursor: pointer;
    font-weight: 500;
    padding: 1.25rem 1.5rem; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
    animation: bannerFadeIn 0.5s;
}

@keyframes bannerFadeIn {
    from { 
        background-color: rgba(255, 255, 255, 0.15) !important;
        border-color:rgba(255, 255, 255, 0.35) !important;
        margin-top: 2rem;
        cursor: pointer;
        font-weight: 500;
        padding: 1.25rem 1.5rem;
        display: inline-block;
     }
    to   { 
        background-color: #C71C4F !important;
        border-color:#C71C4F !important;
        margin-top: 2rem;
        cursor: pointer;
        font-weight: 500;
        padding: 1.25rem 1.5rem;
        display: inline-block;
    }
  }
  
  /* Firefox < 16 */
  
  /* Safari, Chrome and Opera > 12.1 */
  
  /* Internet Explorer */
  
  /* Opera < 12.1 */
  
  /************ FADE OUT ************/
  @keyframes modalFadeOut {
    to { 
        background-color: rgba(255, 255, 255, 0.15) !important;
        border-color:rgba(255, 255, 255, 0.35) !important;
        margin-top: 2rem;
        cursor: pointer;
        font-weight: 500;
        padding: 1.25rem 1.5rem;
        display: inline-block;
     }
    from   { 
        background-color: #C71C4F !important;
        border-color:#C71C4F !important;
        margin-top: 2rem;
        cursor: pointer;
        font-weight: 500;
        padding: 1.25rem 1.5rem;
        display: inline-block;
    }
  }
  
  /* Firefox < 16 */
  
  /* Safari, Chrome and Opera > 12.1 */
  
  /* Internet Explorer */
  
  /* Opera < 12.1 */
.Card-body{
    background-color: white;
    border-radius: 10px;
    height: 100%;
    box-shadow: 0px 15px 20px rgba(150, 50, 85, 0.1);
    transition: transform .3s ease-out;
}

.Card-title{
    color: #BE5A78;
    font-weight: 800;
    font-size: 1.5rem;
}

/******** MOBILE ********/

@media only screen and (max-width: 768px) {
  .App-title{
    font-size: 2.1rem;
  }
  .App-text{
    font-size: 1.15rem !important;
    font-weight: 500;
    margin-bottom: 2.5rem;
  }
}

.Card-img{
    width: 100%;
    border-radius: 10px;
}

.Card-body:hover {
  transform: translate(0, -5px);
  cursor: pointer;
  box-shadow: 0px 15px 20px rgba(150, 50, 85, 0.05);
}
.Tradition-overlay{
    background-color: rgba(255, 255, 255, 0.85);
    width: 100%;
    min-height: 100vh;
    padding: 2rem 1rem 3rem 1rem;
}

.Tradition-quote{
    font-weight: bold;
    font-size: 2rem;
    line-height: 3.5rem;
    margin-bottom: 2rem;
    color: #C71C4F;
}

.Tradition-icon{
    height: 30px;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.Tradition-source{
    font-weight: 600;
    color: #C71C4F;
    font-size: 1rem;
    margin: 2rem 0rem 3rem 0rem;
}

.Tradition-button{
    border-radius: 100rem;
    margin: 1rem 1rem;
    border: 3px solid #1e1cb9;
    background-color: #1e1cb9;
    font-size: 1.2rem;
    padding: 1rem 2rem;
    font-weight: 600;
    color: white;
}

.Tradition-repeat-button{
    border-radius: 100rem;
    margin: 1rem 1rem;
    border: 3px solid #C71C4F;
    background-color: transparent;
    font-size: 1.2rem;
    padding: 1rem 2rem;
    font-weight: 600;
    color: #C71C4F; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
    animation: offHoverFadet 0.5s;
}

.Tradition-repeat-button:hover{
    border-radius: 100rem;
    margin: 1rem 1rem;
    border: 3px solid #C71C4F;
    background-color: #C71C4F;
    font-size: 1.2rem;
    padding: 1rem 2rem;
    font-weight: 600;
    color: white; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
    animation: onHoverFade 0.5s;
}

@media only screen and (max-width: 768px) {
    .Tradition-overlay{
        min-height: 100vh;
        padding: 2rem 0.8rem 3rem 0.8rem;
    }
    .Tradition-quote{
        font-size: 1.6rem;
        line-height: 2.2rem;
    }
    .Tradition-icon{
        height: 18px;
    }
    .Tradition-source{
        font-size: 0.9rem;
        line-height: 1.5rem;
        margin: 2rem 0rem 2rem 0rem;
    }
    .Tradition-button{
        font-size: 1rem;
        padding: 0.8rem 1.6rem;
    }
    
    .Tradition-repeat-button{
        font-size: 1rem;
        padding: 0.8rem 1.6rem; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
        animation: ignore 0.5s;
    }
    .Tradition-repeat-button:hover{
        font-size: 1rem;
        padding: 0.8rem 1.6rem; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
        animation: ignore 0.5s;
    }
}


@keyframes onHoverFade {
    from {     
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: transparent;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: #C71C4F; 
    }
    to   {
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: #C71C4F;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: white;
    }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */

/* Internet Explorer */

/* Opera < 12.1 */

/************ FADE OUT ************/
@keyframes offHoverFadet {
    to {     
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: transparent;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: #C71C4F; 
    }
    from   {
        border-radius: 100rem;
        margin: 1rem 1rem;
        border: 3px solid #C71C4F;
        background-color: #C71C4F;
        font-size: 1.2rem;
        padding: 1rem 2rem;
        font-weight: 600;
        color: white;
    }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */

/* Internet Explorer */

/* Opera < 12.1 */
